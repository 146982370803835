<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div
      v-if="contacts.length"
      class="accordion"
      role="tablist"
    >
      <draggable
        animation="200"
        ghost-class="ghost-sort"
        :list="contacts"
        @change="changedSorting"
      >
        <div
          v-for="contact in contacts"
          :key="contact.id"
          class="accordion__item "
        >
          <div class="accordion-label accordion-label--disable-arrow">
            <div class="accordion-label__content">
              <div class="row w-100 align-items-center">
                <div class="col-2">
                  <feather-icon
                    icon="MenuIcon"
                    class="mr-5"
                  />
                </div>
                <div class="col-10 col-lg-5">
                  {{ contact.title }}
                </div>
                <div class="col-10 col-lg-5 offset-2 offset-lg-0">
                  {{ contact.address }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <div v-else>
      Пока нет ни одного контакта
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Draggable from 'vuedraggable'
import Contact from '@/api/http/models/contact/Contact'
import { BOverlay } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BOverlay,
    Draggable,
  },
  data() {
    return {
      showLoading: true,
      contacts: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await Contact.getAll(this.currentSite.slug)

    if (response.data) {
      this.contacts = response.data
      this.showLoading = false
    }
  },
  methods: {
    async changedSorting() {
      const contacts = this.contacts.map((contact, index) => ({
        id: contact.id,
        order: index + 1,
      }))

      const response = await Contact.sort(this.currentSite.slug, {
        contacts,
      })

      if (response.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Контакты отсортированы',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }
    },
  },
}
</script>
